<template>
    <div class="empty">
<!--        검색결과가 없습니다.-->
        {{emptyText()}}
    </div>
</template>
<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "BoardEmptyDefaultLayout",
        inject: {
            BoardListHasFilter: {
                default: () => {},
            }
        },
        props: {
        },
        data() {
            return {
                noDate: false,
            }
        },
        computed: {},
        mounted() {

        },
        methods: {
            emptyText() {
                return this.BoardListHasFilter() ? '검색결과가 없습니다.' : '게시글이 없습니다.'
            },
        },
    }
</script>

<style scoped>

</style>